<template>
    <el-pagination 
        class="pagination-box" 
        background 
        layout="prev, pager, next, jumper" 
        :hide-on-single-page="true"
        :total="Number(props.total)"
        :page-size="props.pageSize"
        v-model:current-page="state.localCurrentPage">
    </el-pagination>
</template>

<script setup>
import { defineProps, reactive, watch, defineEmits } from 'vue'

const props = defineProps({
    currentPage: { // 当前页码
        type: [Number, String],
        default: 1
    },
    total: {
        type: [Number, String]
    },
    pageSize: {
        type: Number,
        default: 10
    }
})

const state = reactive({
    localCurrentPage: 1
});

const emit = defineEmits(["change"])

watch( () => props.currentPage, (val) => {
    state.localCurrentPage = val
})
watch( () => state.localCurrentPage, (val) => {
    emit('change', val)
})
</script>

<style lang="scss" scoped>
.pagination-box {
    justify-content: center;
    ::v-deep input{
        display: block;
    }
}
</style>
