<template>
    <div class="title">
        <div>{{title}}</div>
        <div class="tips">
            <slot name="tips"></slot>
        </div>
        <slot name="btn"></slot>
    </div>
</template>

<script>
export default {
    props: ["title"]
}
</script>

<style lang="scss" scoped>
.title {
    width: 100%;
    position: relative;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 1px solid #E8E8E8;
    line-height: 1;
    padding-bottom: 20px;
    display: flex;
    align-items: flex-end;
    .tips {
        font-weight: normal;
        line-height: 19px;
        font-size: 16px;
        color: #7F7F7F;
        margin-left: 21px;
    }
}
</style>
