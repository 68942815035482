<template>
    <div class="fans-box">
        <CompHeader title="关注的人" />
        <div class="tabs">
            <div :class="['tabs-items', {'active': followed == 1}]" @click="change(1)">我的关注 {{follow}}</div>
            <div :class="['tabs-items', {'active': followed == 2}]" @click="change(2)">我的粉丝 {{fans}}</div>
        </div>
        <template v-if="isLoad">
            <template v-if="followed == 2">
                <template v-if="fans_list.length">
                    <div class="list-box">
                        <template v-for="(item, index) in fans_list" :key="index">
                            <CompPeople :item="item"/>
                        </template>
                    </div>
                    <CompPagination key="1" :total="fans" :pageSize="fans_page_size" @change="fansPageChange" />    
                </template>
                <CompError
                    v-else
                    class="cont-nothing-box"
                    message="暂无粉丝"
                    icon-name="empty_focus_icon"
                    />
            </template>
            <template v-else>
                <template v-if="follow_list.length">
                    <div class="list-box">
                        <template v-for="(item, index) in follow_list" :key="index">
                            <CompPeople :item="item"/>
                        </template>
                    </div>
                    <CompPagination key="2" :total="follow" :pageSize="follow_page_size" @change="followPageChange"/>   
                </template>
                <CompError
                    v-else
                    class="cont-nothing-box"
                    message="暂无关注的人"
                    icon-name="empty_focus_icon"
                    />
            </template>
        </template>
    </div>
</template>

<script>
import CompHeader from "@@/common/header.vue"
import CompPagination from "@@/common/pagination.vue"
import CompPeople from "@@/user/people.vue"
import CompError from "@@/common/error.vue"

import { followList, fanList } from "@api/index";
import { mapState } from 'vuex'
export default {
    components: {
        CompHeader,
        CompPagination,
        CompPeople,
        CompError
    },
    computed: {
        ...mapState({
            follow: state => state.user.userDetail.follow_count,
            fans: state => state.user.userDetail.follower_count,
        }),
    },
    data() {
        return {
            isLoad: false,
            follow_list:[],
            follow_page: 1,
            follow_page_size: 16,

            fans_list:[],
            fans_page: 1,
            fans_page_size: 16,

            followed: 1,
            user_id: '',
            to_user_id: '',
            is_next: true
        }
    },
    async mounted() {
        const query = this.$route.query
        if (query.tab == 'fans') {
            this.followed = 2
            await this.getFanList()
        } else {
            await this.getFollowList()
        }
        this.isLoad = true
    },
    methods: {
        change(type) {
            this.followed = type
            if (type == 1) {
                this.$router.replace('/user/follow')

                this.follow_page = 1
                this.getFollowList()
            } else if (type == 2) {
                this.$router.replace('/user/follow?tab=fans')

                this.fans_page = 1
                this.getFanList()
            }
        },
        // 获取关注列表
        async getFollowList() {
            const { status, data } = await followList({
                user_id: this.userId,
                to_user_id: this.userId,
                page: this.follow_page,
                page_size: this.follow_page_size,
            });
            if (status == 0) {
                this.is_next = data.is_next;
                this.follow_list = data.list;
            }
        },
        async getFanList() {
            const { status, data } = await fanList({
                user_id: this.userId,
                page: this.fans_page,
                page_size: this.fans_page_size,
            });
            if (status == 0) {
                this.is_next = data.is_next;
                this.fans_list = data.list;
            }
        },
        followPageChange(val) {
            this.follow_page = val
            this.getFollowList()
        },
        fansPageChange(val) {
            this.fans_page= val
            this.getFanList()
        }
    }
}
</script>
<style lang="scss" scoped>
.fans-box {
    .tabs {
        display: flex;
        line-height: 1;
        border-bottom: 1px solid #E8E8E8;
        font-size: 16px;
        &-items {
            margin-right: 30px;
            padding: 27px 0 30px;
            cursor: pointer;
            &.active {
                font-weight: bold;
                position: relative;
                &::after {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 6px;
                    background: #1A1A1A;
                    border-radius: 2px;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    bottom: 0;
                }
            }
        }
    }
    .list-box {
        padding-bottom: 40px;
    }
}
</style>
