<template>
    <div class="box">
        <div class="cont-nothing">
            <img class="img" :src="`https://p6.moimg.net/mdwxapp/${iconName}.png`" />
            <div class="tips">{{message}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: { // 提示信息
            type: String,
            default: ''
        },
        iconName: {
            type: String,
            default: 'empty_project_create_icon'
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    margin: 20px 0;
    .cont-nothing {
        text-align: center;
        .img {
            width: 130px;
            height: 130px;
        }
        .tips {
            margin-top: 12px;
            font-size: 12px;
            color: #9E9E9E;
            line-height: 1;
        }
    }
    
}
</style>