<template>
    <div class="fans-item">
        <div class="img" @click="goUserInfo">
            <CompAvtor :img_src="item.icon" size="120" />
        </div>
        <div class="info" @click="goUserInfo">
            <div class="name">{{item.nickname}}</div>
            <div class="sign">{{item.user_content}}</div>
        </div>
        <el-button 
            class="btn"
            :type="item.relation == 1 || item.relation == 3 ? '' : 'primary'" 
            @click="followPeople(item)"
            >{{relationMap[item.relation]}}</el-button>
    </div>
</template>

<script>
import { follow } from "@/api/index";
import CompAvtor from "@@/compose/avtor.vue"
export default {
    props: ['item'],
    components: {
        CompAvtor
    },
    data() {
        return {
            relationMap: {
                1: "已关注",
                2: "关注",
                3: "互相关注",
                '-2': "关注",
                '-1': "关注"
            },
        }
    },
    methods: {
        async followPeople(item) {
            let msg = ''
            let type = 1
            let relation = 1
            switch(item.relation){
                case 1:
                    msg = '取消成功'
                    type = 2
                    relation = -2
                    break;
                case 3:
                    msg = '取消成功'
                    type = 2
                    relation = 2
                    break;
                case 2:
                    msg = '关注成功'
                    type = 1
                    relation = 3
                    break;
                case -2:
                    msg = '关注成功'
                    type = 1
                    relation = 1
                    break;
                case -1:
                    msg = '关注成功'
                    type = 1
                    relation = 1
                    break;
            }
            const { status } = await follow({
                to_user_id: item.user_id || item.id,
                type: type // 1-关注 2-取消
            });
            if (status == 0) {
                item.relation = relation;
                this.$message.success(msg)
            }
        },
        goUserInfo() {
            this.$router.push(`/user/homepage/${this.item.user_id}`)
        }
    }
}
</script>

<style lang="scss" scoped>
.fans-item {
    padding: 20px 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid #E8E8E8;
    .img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px solid #E8E8E8;
        margin-right: 10px;
        cursor: pointer;
        overflow: hidden;
    }
    .info {
        flex: 1;
        width: 0;
        display: flex;
        flex-direction: column;
        line-height: 24px;
        cursor: pointer;
        .name {
            font-size: 18px;
            margin-bottom: 1;
            font-weight: bold;
        }
        .sign {
            color: #7F7F7F;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .btn {
        margin-left: 20px;
    }
}
</style>